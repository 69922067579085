import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Alert, AlertTitle, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import UpdateIcon from '@mui/icons-material/Update';

import { getCheckInStatus, performCheckIn } from '../CheckinSlice';
import { isEmpty } from '../../../utils/Utils';


function CheckinComplete () {
    const { isCheckingIn, checkinKey, checkin, checkinResult } = useSelector((state) => state.checkin);
    const { signature } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [result, setResult] = useState('');

    useEffect(() => {
        if (!checkin.ccard_authorized && checkin.authorize_ccard) {
            setOpenDialog(true);
        } else {
            if (isEmpty(checkinResult)) {
                const keyData = {
                    checkinKey: checkinKey
                }
                dispatch(performCheckIn(keyData));
            } else {
                const roomsRequired = parseInt(checkinResult.rooms_required);
                const roomsCheckedIn = parseInt(checkinResult.rooms_checked_in);
                if (roomsRequired === roomsCheckedIn) {
                    setResult('success');
                } else if (roomsCheckedIn < roomsRequired && roomsCheckedIn > 0) {
                    setResult('warning')
                } else {
                    setResult('error');
                }
            }
        }
    }, [checkin, checkinKey, checkinResult, dispatch])

    const displayResult = () => {
        const alertTitle = {
            success: 'Success',
            warning: 'Partial success',
            error: 'Error'
        }
        const messageMap = {
            success: 'Check-in is complete.',
            warning: `${checkinResult.rooms_checked_in} of ${checkinResult.rooms_required} rooms checked in.`,
            error: 'Check-in could not be completed.'
        }
        const message = messageMap[result];
        return (
            <Alert 
                severity={result}
                iconMapping={{
                    success: <CheckCircleIcon fontSize='large' />,
                    warning: <WarningIcon fontSize='large' />,
                    error: <ErrorIcon fontSize='large' />
                }}
                variant='filled'
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                <AlertTitle>{alertTitle[result]}</AlertTitle>
                {message}
            </Alert>
        )
    }

    const handleClose = () => {
        navigate(`/checkin/${signature}/start`);
        setOpenDialog(false);
    }

    const handleRefresh = () => {
        dispatch(getCheckInStatus(checkinKey));
    }

    return (
        <Container maxWidth='sm'>
            { isCheckingIn ? (
                <Grid container spacing={2} align='center'sx={{ marginTop: 3 }} >
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2' gutterBottom>
                            Checking you in ...
                        </Typography>
                    </Grid>
                </Grid>
            ) : ( !isEmpty(checkinResult) ?
                <Card sx={{ marginTop: 3 }} >
                    <CardContent>
                        <Grid container spacing={0} alignItems='center' justify='center'>
                            <Grid item xs={12}>
                                { displayResult() }
                            </Grid>
                        </Grid>  
                    </CardContent>
                    <CardContent>
                        <Grid container spacing={0} alignItems='center' justify='center'>
                            <Grid item xs={12}>
                                {result === 'success' ? (
                                    <Alert severity='info' icon={false}>
                                        Welcome {checkin.guest_first_name} to {checkin.hotel_name}!
                                        Get ready for a memorable stay with us.
                                    </Alert>
                                ) : (
                                    <Alert severity='info' sx={{ display: 'flex', alignItems: 'center' }}>
                                        Please visit our reception on arrival to finalise your check-in.
                                        We look forward to welcoming you at {checkin.hotel_name}.
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                        
                        
                    </CardContent>
                </Card>
                : <span />
            )}
            <Dialog
                open={openDialog}
            >
                <DialogContent>
                    <DialogContentText>
                        The pre-authorization status could not be confirmed.
                        If your pre-authorization was not successful, check your email for the Mobipaid link and re-try.
                        Otherwise, refresh the pre-authorization status.
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={handleClose} color='secondary'>Cancel</Button>
                        <Button onClick={handleRefresh} endIcon={<UpdateIcon />}>Re-fresh</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </Container>
    )
}

export default CheckinComplete;