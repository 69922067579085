import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material'


function HomePage () {
    const poweredByN3S = '/powered-by-n3s.png'
    return(
        <Container maxWidth='sm'>
            <Grid container spacing={2} align='center'>
                <Grid item xs={12}>
                    <Card sx={{ marginTop: 3 }}>
                        <CardContent>
                            <Typography variant='body2'>
                                This service is
                            </Typography>
                            <Link to="https://www.n3services.com" target="_blank" rel="noopener noreferrer">
                                <CardMedia
                                    component='img'
                                    image={poweredByN3S}
                                    alt='powered by N3 Services'
                                    sx={{ maxWidth: '40%', maxHeight: 'auto' }}
                                />
                            </Link>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    )
}

export default HomePage;