import { Box, Typography } from "@mui/material";

export default function ErrorPage() {

    return (
        <div id="error-page">
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 5 }}>
                <Typography variant="subtitle1" color='secondary' gutterBottom>
                    Oops
                </Typography>
                <Typography variant="body1" color='secondary' gutterBottom>
                    Sorry, an unexpected error has occurred.
                </Typography>
            </Box>
        </div>
    )
}