import React from 'react';
import { useSelector } from 'react-redux';
import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import { isEmpty } from './Utils';

function MenuAppBar ({ title }) {
    const { checkin } = useSelector((state) => state.checkin);
    const { checkout } = useSelector((state) => state.checkout);
    const theme = useTheme();

    const imageSize = theme.breakpoints.values.sm === 600 ? 30 : 50;
    const LogoBox = styled(Box)(({ theme }) => ({
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginRight: theme.spacing(2),
    }))

    const branding = isEmpty(checkout) ? checkin : checkout;

    return (
        <AppBar position='static' style={{ backgroundColor: '#2E02A8' }}>
            <Container maxWidth='xl'>
                <Toolbar disableGutters>
                    <LogoBox>
                        {branding.logo_url ? <img src={branding.logo_url} alt='Property logo' width={imageSize} height={imageSize} /> :  null}
                    </LogoBox>
                    <Typography textAlign='center' marginRight={theme.spacing(2)}>
                        {branding.hotel_name}
                    </Typography>
                    <Typography textAlign='center'>
                        {title}
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default MenuAppBar;