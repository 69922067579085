import moment from 'moment';
import React, {useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Button, CircularProgress, Container, Divider, Grid, Paper, TextField, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker/TimePicker'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { postCheckInProfile } from '../CheckinSlice';
import { isEmpty, getPrimaryGuestNames } from '../../../utils/Utils';
import SearchField from '../../../utils/SearchField';
import { getCountries, getRegions, getCities } from '../../../utils/ApiCalls';


function CheckinProfile () {
    const { profile, profileId, address, communication, reservation, checkinKey } = useSelector((state) => state.checkin);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [passport, setPassport] = useState(null);
    const [updatedProfile, setUpdatedProfile] = useState({});

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const stateCountry = address.address?.country;
                const stateRegion = address.address?.state;
                const stateCity = address.address?.cityName;
                const stateNationality = profile.nationality;
                const initialValues = {};

                const countryList = await getCountries();
                setCountries(countryList);
                const country = stateCountry ? countryList.find(country => country.code2 === stateCountry.code || country.name === stateCountry.value) : {};
                const nationality = stateNationality ? countryList.find(country => country.code2 === stateNationality) : {};
                initialValues.country = country;
                initialValues.nationality = nationality
                if (country) {
                    const regionList = await getRegions(country.id);
                    setRegions(regionList)
                    const region = regionList && stateRegion ? regionList.find(region => region.name === stateRegion) : {};
                    initialValues.region = region;

                    if (region) {
                        const cityList = await getCities(country.id, region.id);
                        setCities(cityList);
                        const city = cityList && stateCity ? cityList.find(city => city.name === stateCity) : {};
                        initialValues.city = city;
                    }
                }
                
                const idList = profile.identifications?.identificationInfo;
                const passportDetails = !isEmpty(idList) ? idList.find(id => id.identification.idType.toUpperCase() === 'PASSPORT' && id.identification.primaryInd === true) : '';
                setPassport(passportDetails);
                const primaryGuestNames = getPrimaryGuestNames(profile);
                const expectedArrivalTime = reservation.roomStay?.expectedTimes?.reservationExpectedArrivalTime ? 
                    moment(reservation.roomStay.expectedTimes.reservationExpectedArrivalTime) :
                    moment(reservation.roomStay.arrivalDate + " 14H:00")
                const profileDetails = {
                    firstName: primaryGuestNames.firstName,
                    lastName: primaryGuestNames.lastName,
                    address: address.address?.addressLine ? address.address?.addressLine.join(', ') : '',
                    country: initialValues.country || '',
                    region: initialValues.region || '',
                    city: initialValues.city || '',
                    email: communication.email.email?.emailAddress ? communication.email.email.emailAddress : '',
                    phone: communication.phone.telephone?.phoneNumber ? communication.phone.telephone?.phoneNumber : '',
                    nationality: initialValues.nationality || '',
                    passport: passportDetails ? passportDetails.identification.idNumber : '',
                    preferences: '',
                    allergies: '',
                    disabilities: '',
                    arrivalTime: expectedArrivalTime,
                    departureDate: reservation.roomStay?.departureDate
                };
                setUpdatedProfile(profileDetails);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [profile, address, communication, reservation])


    const handleProfileUpdate = (event) => {
        const { name, value } = event.target;
        setUpdatedProfile((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const handleNationalityUpdate = (event, newValue) => {
        setUpdatedProfile((prevData) => ({
            ...prevData,
            nationality: newValue
        }))
    }

    const handleCountryUpdate = (event, newValue) => {
        setUpdatedProfile((prevData) => ({
            ...prevData,
            country: newValue,
            region: '',
            city: ''
        }));
        getRegions(newValue.id).then(data => setRegions(data));
        setCities([]);
    }

    const handleRegionUpdate = (event, newValue) => {
        setUpdatedProfile((prevData) => ({
            ...prevData,
            region: newValue,
            city: ''
        }));
        getCities(null, newValue.id).then(data => setCities(data));
    }

    const handleCityUpdate = (event, newValue) => {
        setUpdatedProfile((prevData) => ({
            ...prevData,
            city: newValue
        }))
    }

    const validateFormInput = () => {
        let isValid = true;
        if (isEmpty(updatedProfile.nationality)) {
            isValid = false;
            toast.error('Nationality is required');
        }
        if (isEmpty(updatedProfile.country)) {
            isValid = false;
            toast.error('Country is required');
        }
        if (isEmpty(updatedProfile.region)) {
            isValid = false;
            toast.error('Region is required');
        }
        if (isEmpty(updatedProfile.city)) {
            isValid = false;
            toast.error('City is required');
        }
        return isValid;
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateFormInput()) {
            const arrivalDatetime = updatedProfile.arrivalTime
            const profileData = {
                checkinKey: checkinKey,
                profile: updatedProfile,
                ids: {
                    profile_id: profileId,
                    address_id: !isEmpty(address.id) ? address.id : '',
                    telephone_id: !isEmpty(communication.phone?.id) ? communication.phone?.id : '',
                    email_id: !isEmpty(communication.email?.id) ? communication.email?.id : '',
                    identification_id: !isEmpty(passport) ? passport.id : ''
                },
            }
            profileData.profile.arrivalTime = arrivalDatetime.format('YYYY-MM-DDTHH:mm:ss');
            dispatch(postCheckInProfile(profileData));
            navigate(`/checkin/${checkinKey}/auth`);
        }
    }

    return (
        <Container maxWidth='sm'>
            { !isEmpty(updatedProfile) ? (
                <Paper sx={{ marginTop: 3, padding: 2 }}>
                <form onSubmit={handleSubmit}>
                    <Typography variant='h5' gutterBottom>
                        Primary guest details
                    </Typography>
                    <Typography variant='overline' color='primary' display='block' gutterBottom>Identity</Typography>
                    <Divider/>
                    <TextField
                        label='First name'
                        name='firstName'
                        value={updatedProfile.firstName || ''}
                        onChange={handleProfileUpdate}
                        fullWidth
                        margin='normal'
                        required
                        size='small'
                    />
                    <TextField
                        label='Last name'
                        name='lastName'
                        value={updatedProfile.lastName || ''}
                        onChange={handleProfileUpdate}
                        fullWidth
                        required
                        margin='normal'
                        size='small'
                    />
                    <SearchField
                        options={countries}
                        value={updatedProfile.nationality || ''}
                        onChange={handleNationalityUpdate}
                        label={'Nationality'}
                    />
                    <TextField
                        label='Passport number'
                        name='passport'
                        value={updatedProfile.passport || ''}
                        onChange={handleProfileUpdate}
                        fullWidth
                        required
                        margin='normal'
                        size='small'
                    />
                    <Typography variant='overline' color='primary' display='block' gutterBottom>Contact details</Typography>
                    <Divider/>
                    <TextField
                        label='Email'
                        type='email'
                        name='email'
                        value={updatedProfile.email || ''}
                        onChange={handleProfileUpdate}
                        fullWidth
                        required
                        margin='normal'
                        size='small'
                    />
                    <TextField
                        label='Phone'
                        name='phone'
                        value={updatedProfile.phone || ''}
                        onChange={handleProfileUpdate}
                        fullWidth
                        required
                        margin='normal'
                        size='small'
                    />
                    <Typography variant='overline' color='primary' display='block' gutterBottom>Residential address</Typography>
                    <Divider/>
                    <TextField
                        label='Address'
                        name='address'
                        value={updatedProfile.address || ''}
                        onChange={handleProfileUpdate}
                        fullWidth
                        multiline
                        maxRows={2}
                        margin='normal'
                        size='small'
                    />
                    <SearchField
                        options={countries}
                        value={updatedProfile.country || ''}
                        onChange={handleCountryUpdate}
                        label={'Country'}
                    />
                    <SearchField
                        options={regions}
                        value={updatedProfile.region || ''}
                        onChange={handleRegionUpdate}
                        label={'Region'}
                    />
                    <SearchField
                        options={cities}
                        value={updatedProfile.city || ''}
                        onChange={handleCityUpdate}
                        label={'City'}
                    />
                    <Typography variant='overline' color='primary' display='block' gutterBottom>About your stay</Typography>
                    <Divider/>
                    <TimePicker
                        label='Expected time of arrival'
                        value={updatedProfile.arrivalTime}
                        minutesStep={15}
                        onChange={(newValue) => setUpdatedProfile((prevData) => ({...prevData, arrivalTime: moment(newValue)}))}
                        sx={{
                            width: '100%',
                            '& .MuiInputBase-root': { height: '40px' },
                            marginTop: '20px',
                            marginBottom: '10px'
                        }}
                    />
                    <TextField
                        label='Preferences'
                        name='preferences'
                        value={updatedProfile.preferences || ''}
                        onChange={handleProfileUpdate}
                        fullWidth
                        multiline
                        maxRows={2}
                        margin='normal'
                        size='small'
                    />
                    <TextField
                        label='Allergies'
                        name='allergies'
                        value={updatedProfile.allergies || ''}
                        onChange={handleProfileUpdate}
                        fullWidth
                        multiline
                        maxRows={2}
                        margin='normal'
                        size='small'
                    />
                    <TextField
                        label='Disabilities'
                        name='disabilities'
                        value={updatedProfile.disabilities || ''}
                        onChange={handleProfileUpdate}
                        fullWidth
                        multiline
                        maxRows={2}
                        margin='normal'
                        size='small'
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <Button type='submit' color='success' variant='contained' endIcon={<NavigateNextIcon />}>Next</Button>
                    </Box>
                </form>
                </Paper>
            ) : (
                <Grid container spacing={2} align='center' sx={{ marginTop: 3 }}>
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2' gutterBottom>
                            Loading details ...
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </Container>
    )
}

export default CheckinProfile;