import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { toastOnError } from '../../utils/Utils';

const initialState = {
    isLoading: false,
    checkoutKey: '',
    checkout: {},
    folioBalances: [],
    folioDetails: {},
    currencyTotals: {},
    departureTime: '',
    isSettled: false,
    paymentUrl: '',
    reservationStatus: '',
    isCheckingOut: false,
    checkoutResult: {}
}


export const verifyCheckOut = createAsyncThunk(
    'checkOutVerify',
    async (signature, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get(`/checkout/verify/?signature=${signature}`);
            dispatch(setKey(response.data));
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const getCheckOutDetails = createAsyncThunk(
    'checkOutDetails',
    async (key, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get(`/checkout/start?key=${key}`);
            dispatch(setCheckoutDetails(response.data));
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const getCheckOutStatus = createAsyncThunk(
    'checkOutStatus',
    async (key, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get(`checkout/status?key=${key}`);
            dispatch(setIsSettled(response.data));
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const performCheckOut = createAsyncThunk(
    'performCheckOut',
    async (keyData, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.post('checkout/complete/', keyData);
            dispatch(setCheckOutResult(response.data));
            return response.data;
        } catch (error) {
            // toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        setKey (state, action) {
            state.checkoutKey = action.payload.response.key;
        },
        setCheckoutDetails (state, action) {
            state.checkout = action.payload.checkout;
            state.folioBalances = action.payload.folio_balances;
            state.folioDetails = action.payload.folio_details;
            state.currencyTotals = action.payload.currency_totals;
            state.departureTime = action.payload.departure_time;
            state.isSettled = action.payload.isSettled;
            state.paymentUrl = action.payload.payment_url;
            state.reservationStatus = action.payload.reservation_status;
        },
        setIsSettled (state, action) {
            state.isSettled = action.payload.isSettled;
        },
        setCheckOutResult (state, action) {
            state.checkoutResult = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(verifyCheckOut.pending, (state) => {
            state.isLoading = true;
        }).addCase(verifyCheckOut.fulfilled, (state) => {
            state.isLoading = true;
        }).addCase(verifyCheckOut.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getCheckOutDetails.pending, (state) => {
            state.isLoading = true;
        }).addCase(getCheckOutDetails.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getCheckOutDetails.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getCheckOutStatus.pending, (state) => {
            state.isLoading = true;
        }).addCase(getCheckOutStatus.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getCheckOutStatus.rejected, (state) => {
            state.isLoading = false;
        }).addCase(performCheckOut.pending, (state) => {
            state.isCheckingOut = true;
        }).addCase(performCheckOut.fulfilled, (state) => {
            state.isCheckingOut = false;
        }).addCase(performCheckOut.rejected, (state) => {
            state.isCheckingOut = false;
        })
    }
})

export const { setKey, setCheckoutDetails, setIsSettled, setCheckOutResult } = checkoutSlice.actions;
export default checkoutSlice.reducer;