import { toast } from 'react-toastify';


export const toastOnError = error => {
    if (error.response) {
        toast.error(JSON.stringify(error.response.data), {toastId: 'error1'});
    } else if (error.message) {
        toast.error(JSON.stringify(error.message), {toastId: 'error2'});
    } else {
        toast.error(JSON.stringify(error), {toastId: 'error3'});
    }
}


export const isEmpty = value => 
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'object' && Object.values(value).every(value => value === '' || value === null)) ||
    (typeof value === 'string' && value.trim().length === 0)

    
export function getPrimaryGuestNames (profile) {
    const guest = profile.personName.find((name) => name.nameType.toUpperCase() === 'PRIMARY')
    return {
        firstName: guest?.givenName || '',
        lastName: guest?.surname || ''
    }
}

