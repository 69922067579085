import React from 'react';
import axios from 'axios';
import moment from 'moment'
import 'moment-timezone';
import { LocalizationProvider } from '@mui/x-date-pickers/';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import RouteErrorPage from './utils/RouteErrorPage';
import HomePage from './components/homepage/HomePage';
import Checkin from './components/checkin/Checkin';
import CheckinStart from './components/checkin/checkinStart/CheckinStart';
import CheckinProfile from './components/checkin/checkinProfile/CheckinProfile';
import CheckinAuth from './components/checkin/checkinAuth/CheckinAuth';
import CheckinComplete from './components/checkin/checkinComplete/CheckinComplete'
import Checkout from './components/checkout/Checkout';
import CheckoutStart from './components/checkout/checkoutStart/CheckoutStart';
import CheckoutComplete from './components/checkout/checkoutComplete/CheckoutComplete';

import { mainURL } from './utils/ApiCalls';

// Set api default path
axios.defaults.baseURL = mainURL;
moment.tz.setDefault('UTC');

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Routes>
        <Route exact path='/' element={ <HomePage /> } errorElement={ <RouteErrorPage /> } />
        <Route path='/checkin/:signature' element={ <Checkin /> } errorElement={ <RouteErrorPage /> }>
          <Route path='start' element={ <CheckinStart /> } errorElement={ <RouteErrorPage /> } />
          <Route path='profile' element={ <CheckinProfile /> } errorElement={ <RouteErrorPage /> } />
          <Route path='auth' element={ <CheckinAuth /> } errorElement={ <RouteErrorPage /> } />
          <Route path='complete' element={ <CheckinComplete /> } errorElement={ <RouteErrorPage /> } />
        </Route>
        <Route path='checkout/:signature' element={ <Checkout /> } errorElement={ <RouteErrorPage /> }>
          <Route path='start' element={ <CheckoutStart /> } errorElement={ <RouteErrorPage /> } />
          <Route path='complete' element={ <CheckoutComplete /> } errorElement={ <RouteErrorPage /> } />
        </Route>
      </Routes>
      <ToastContainer newestOnTop={true} autoClose={4000} limit={3} />
    </LocalizationProvider>
  );
}

export default App;
