import React, {useState, useEffect, useMemo} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { getDisclaimers } from '../../../utils/ApiCalls';
import { isEmpty } from '../../../utils/Utils';

function CheckinAuth () {
    const { isUpdating, checkin, checkinKey, reservation, ccAuthUrl, linkedReservations } = useSelector((state) => state.checkin);
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [disclaimers, setDisclaimers] = useState(null);

    useEffect(() => {
        if (!isUpdating && (!checkin.authorize_ccard || checkin.ccard_authorized)) {
            navigate(`/checkin/${checkinKey}/complete`);
        }
        if (!isUpdating && checkin.authorize_ccard && !checkin.ccard_authorized) {
            setOpenDialog(true);
        }
     }, [isUpdating, checkin, checkinKey, navigate])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getDisclaimers(checkinKey);
                setDisclaimers(response);
            } catch (error) {
                console.error(error);
            }
        }
        fetchData();
    }, [checkinKey]) 

    const preAuthAmount = useMemo(() => {
        const nightsToAuthorize = parseInt(checkin.nights_to_authorize);
        const baseRate = parseFloat(reservation.roomStay.roomRates[0].rates.rate[0].base.amountBeforeTax);
        const currency = reservation.roomStay.roomRates[0].rates.rate[0].base.currencyCode;
        const baseAmount = (nightsToAuthorize * baseRate).toFixed(2);
        
        const finalAmount = checkin.reservation_splitted ? baseAmount * linkedReservations.length : baseAmount

        return (
            <DialogContentText>
                Your payment card will be pre-authorized with an amount of {currency} {finalAmount}. The card will not be debited.
            </DialogContentText>
        )
    }, [checkin, reservation, linkedReservations])

    const disclaimerList = !isEmpty(disclaimers) ? (disclaimers.map((disclaimer) => (
        <Accordion key={disclaimer.id}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={disclaimer.id}
                id={disclaimer.id}
            >
                <Typography variant='subtitle2' align='left'>
                    {disclaimer.title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography variant='body2' align='left'>
                    {disclaimer.text}
                </Typography>
            </AccordionDetails>
        </Accordion>
    ))) : null;

    const handleClose = () => {
        navigate(`/checkin/${checkinKey}/start`);
        setOpenDialog(false);
    }

    const handleConfirm = () => {
        window.location.href = ccAuthUrl;
        setOpenDialog(false);
    }

    return (
        <Container maxWidth='sm'>
            { isUpdating ? (
                <Grid container spacing={2} align='center'sx={{ marginTop: 3 }} >
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2' gutterBottom>
                            Updating details ...
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
             <Dialog
                open={openDialog}
             >
                <DialogTitle>
                    Payment Card Pre-Authorization
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} align='left'>
                        <Grid item xs={12}>
                            {preAuthAmount}
                        </Grid>
                        <Grid item xs={12}>
                            {disclaimerList}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='secondary'>Cancel</Button>
                    <Button onClick={handleConfirm} color='success' variant='contained' endIcon={<NavigateNextIcon />}>Proceed</Button>
                </DialogActions>
             </Dialog>
            )}
        </Container>
    )
}

export default CheckinAuth;