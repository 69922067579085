import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useParams, useLocation } from 'react-router-dom';
import { Backdrop, CircularProgress, Container, Grid, Typography } from '@mui/material';

import MenuAppBar from '../../utils/MenuAppBar';
import ErrorPage from '../../utils/ErrorPage';
import { isEmpty } from '../../utils/Utils';
import { verifyCheckIn, getCheckInDetails, getCheckInStatus } from './CheckinSlice';

function Checkin () {
    const { isLoading, checkinKey, checkin, reservation } = useSelector((state) => state.checkin);
    const [checkinVerified, setCheckinVerified] = useState(false);
    const [detailsRetrieved, setDetailsRetrieved] = useState(false);
    const { signature } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();
    const isCompletePage = location.pathname.endsWith('/complete');

    useEffect(() => {
        if (!checkinVerified) {
            dispatch(verifyCheckIn(signature));
            setCheckinVerified(true);
        }
        if (checkinVerified && !detailsRetrieved && !isEmpty(checkinKey) && !isCompletePage) {
            dispatch(getCheckInDetails(checkinKey));
            setDetailsRetrieved(true);
        }
        if (isCompletePage && !isEmpty(checkinKey)) {
            dispatch(getCheckInStatus(checkinKey));
        }
    }, [signature, checkinVerified, detailsRetrieved, isCompletePage, checkinKey, dispatch])

    return(
        <Container>
            <MenuAppBar
                title='Check-in'
            />
            { isLoading ? (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <Grid container spacing={2} align='center'>
                        <Grid item xs={12}>
                            <Typography variant='body2' gutterBottom>
                                Retrieving your reservation...
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' gutterBottom>
                                Hang on... This might take a minute.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CircularProgress color='inherit' />
                        </Grid>
                    </Grid>
                </Backdrop>
            ) : ( 
                !isEmpty(checkin) && (!isEmpty(reservation) || isCompletePage) ? <Outlet /> : <ErrorPage />
            )}
        </Container>
    )
}

export default Checkin;