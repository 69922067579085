import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Alert, AlertTitle, Box, Button, Card, CardActions, CardContent, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import StartIcon from '@mui/icons-material/Start';
import { isEmpty } from '../../../utils/Utils';


function CheckoutStart () {
    const { checkout, folioDetails, folioBalances, currencyTotals, departureTime, paymentUrl, reservationStatus, isSettled } = useSelector((state) => state.checkout);
    const { signature } = useParams();
    const navigate = useNavigate();

    const sortedBalances = [...folioBalances].sort((item1, item2) => (item1?.window ?? 0) - (item2?.window ?? 0));
    const sortedCurrencyTotals = [...currencyTotals].sort((item1, item2) => (item1?.amount ?? 0) - (item2?.amount ?? 0));
    const checkoutReady = isReadyForCheckOut(reservationStatus, paymentUrl, isSettled);

    const handleStart = () => {
        if (!isSettled && !isEmpty(paymentUrl)) {
            window.location.href = paymentUrl;
        }
        if (isSettled) {
            navigate(`/checkout/${signature}/complete`);
        }
    }

    return (
        <Container maxWidth='sm'>
            <Card sx={{ marginTop: 3 }}>
                <CardContent>
                    <Typography variant='h6' gutterBottom>
                        Folio for your stay at {checkout.hotel_name}
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', marginTop: 3, marginBottom: 2 }}>
                        <Typography variant='body2'>Guest: {checkout.guest_first_name} {checkout.guest_last_name}</Typography>
                        <Typography variant='body2'>Departure: {moment(departureTime).format('D MMM YYYY - hh:mm a')}</Typography>
                    </Box>
                    <TableContainer style={{ display: 'flex', flexDirection: 'column' }}>
                        {isEmpty(sortedBalances) ? (
                            <Table size='small'>
                                <TableHead>
                                    <TableRow key='1-header'>
                                        <TableCell colSpan={4}>
                                            No open folios found.
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        ) : (
                            <Table size='small'>
                                {sortedBalances.map((balance) => (
                                    <>
                                        <TableHead>
                                            <TableRow key={`${balance.window}-header`}>
                                                <TableCell colSpan={4}>
                                                    Folio {balance.window}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {[...folioDetails[String(balance.window)]].sort((item1, item2) => moment(item1.transactionDate).unix() - moment(item2.transactionDate).unix()).map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {item.transactionDate}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.description}
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        {item.debitAmount?.currencyCode || 
                                                        item.creditAmount?.currencyCode}
                                                    </TableCell>
                                                    <TableCell align='right'>
                                                        {item.debitAmount?.amount?.toFixed(2) ||
                                                        (item.creditAmount?.amount ? `-${item.creditAmount?.amount?.toFixed(2)}` : 0)}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow key={`${balance.window}-total`}>
                                                <TableCell colSpan={4} align='right'>
                                                    <Typography variant='subtitle2' gutterBottom>
                                                        Total due {balance.balance.currencyCode} {balance.balance.amount.toFixed(2)}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </>
                                ))}
                                {folioBalances.length > 0 ? (
                                    <TableBody>
                                    {sortedCurrencyTotals.map((total) => (
                                        <TableRow key={total.amount}>
                                            <TableCell colSpan={4} align='right'>
                                                <Typography variant='subtitle2' gutterBottom>
                                                    Grand total {total.currency} {total.amount.toFixed(2)}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                ) : null}
                            </Table>
                        )}
                    </TableContainer>
                    {checkoutReady ? (
                        <Alert severity='success' sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                            <AlertTitle>Check-out status</AlertTitle>
                            Ready for check-out.
                        </Alert>
                    ) : (
                        <Alert severity='warning' sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                            <AlertTitle>Check-out status</AlertTitle>
                            Not available. Please try again later.
                        </Alert>
                    )}
                    {currencyTotals.length > 1 ? (
                        <Alert severity='info' variant='outlined' sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                            Note that separate payments must be made for each currency total.
                        </Alert>
                    ) : null}
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={handleStart} disabled={!checkoutReady} variant='contained' color='success' endIcon={<StartIcon />}>Check-out</Button>
                </CardActions>
            </Card>
        </Container>
    )
}

export default CheckoutStart;


export function isReadyForCheckOut (reservationStatus, paymentUrl, isSettled) {
    return reservationStatus === 'DueOut' && (
        !isEmpty(paymentUrl) || isSettled);
}