import { configureStore } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history';

// Add reducers here
import checkinReducer from './components/checkin/CheckinSlice';
import checkoutReducer from './components/checkout/CheckoutSlice';

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
    reducer: {
        router: routerReducer,
        checkin: checkinReducer,
        checkout: checkoutReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware),
});

export const history = createReduxHistory(store);
