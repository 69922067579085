import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
import { Backdrop, CircularProgress, Container, Grid, Typography } from '@mui/material';

import MenuAppBar from '../../utils/MenuAppBar';
import ErrorPage from '../../utils/ErrorPage';
import { isEmpty } from '../../utils/Utils';
import { verifyCheckOut, getCheckOutDetails } from './CheckoutSlice';


function Checkout () {
    const { isLoading, checkoutKey, checkout } = useSelector((state) => state.checkout);
    const { signature } = useParams();
    const dispatch = useDispatch();
    const [checkoutVerified, setCheckoutVerified] = useState(false);
    const [detailsReceived, setDetailsReceived] = useState(false);

    useEffect(() => {
        if (!checkoutVerified) {
            dispatch(verifyCheckOut(signature));
            setCheckoutVerified(true);
        }
        if (checkoutVerified && !detailsReceived && !isEmpty(checkoutKey)) {
            dispatch(getCheckOutDetails(checkoutKey));
            setDetailsReceived(true);
        }
    }, [checkoutVerified, signature, detailsReceived, checkoutKey, dispatch])

    return (
        <Container>
            <MenuAppBar
                title='Check-out'
            />
            { isLoading ? (
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <Grid container spacing={2} align='center'>
                        <Grid item xs={12}>
                            <Typography variant='body2' gutterBottom>
                                Retrieving your folio...
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' gutterBottom>
                                Hang on... This might take a minute.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <CircularProgress color='inherit' />
                        </Grid>
                    </Grid>
                </Backdrop>
            ) : (
                !isEmpty(checkout) ? <Outlet /> : <ErrorPage />
            )}
        </Container>
    )
}

export default Checkout;