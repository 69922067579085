import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { isEmpty } from './Utils';

function SearchField ({options, value, onChange, label}) {

    const getOptionLabel = (option) => {
        if (isEmpty(option)) {
            return '';
        };
        return option.name
    }

    const isOptionEqualToValue = (option, value) => {
        if (isEmpty(option) || isEmpty(value)) {
            return true;
        }
        return option.id === value.id;
    }
    
    return (
        <Autocomplete
            options={options}
            value={value}
            onChange={onChange}
            renderInput={(params) => <TextField {...params} label={label} style={{ marginTop: 20, marginBottom: 10 }} />}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={isOptionEqualToValue}
            disableClearable
            size='small'
        />
    )
}

export default SearchField