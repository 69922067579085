import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Alert, AlertTitle, Button, Card, CardContent, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import UpdateIcon from '@mui/icons-material/Update';

import { isEmpty } from '../../../utils/Utils';
import { performCheckOut, getCheckOutStatus } from '../CheckoutSlice';


function CheckoutComplete () {
    const { isSettled, isCheckingOut, checkoutResult, checkout, checkoutKey } = useSelector((state) => state.checkout);
    const { signature } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [result, setResult] = useState('');
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (isEmpty(checkoutResult) && isSettled) {
            const keyData = {
                checkoutKey: checkoutKey
            }
            dispatch(performCheckOut(keyData));
        }
        if (!isSettled) {
            setOpenDialog(true);
        }
        if (!isEmpty(checkoutResult) && checkoutResult.result) {
            setResult('success');
        }
        if (!isEmpty(checkoutResult) && !checkoutResult.result) {
            setResult('error');
        }
    }, [checkoutResult, isSettled, checkoutKey, dispatch])

    const displayResult = () => {
        const alertTitle = {
            success: 'Success',
            error: 'Error'
        }
        const messageMap = {
            success: 'Check-out complete.',
            error: 'Check-out could not be completed.'
        }
        const message = messageMap[result];
        return (
            <Alert
                severity={result}
                iconMapping={{
                    success: <CheckCircleIcon fontSize='large' />,
                    error: <ErrorIcon fontSize='large' />
                }}
                variant='filled'
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                <AlertTitle>{alertTitle[result]}</AlertTitle>
                {message}
            </Alert>
        )
    }

    const handleClose = () => {
        navigate(`/checkout/${signature}/start`);
        setOpenDialog(false);
    }

    const handleRefresh = () => {
        dispatch(getCheckOutStatus(checkoutKey));
        navigate(`/checkout/${signature}/start`);
        setOpenDialog(false);
    }

    return (
        <Container maxWidth='sm'>
            { isCheckingOut ? (
                <Grid container spacing={2} align='center' sx={{ marginTop: 3 }}>
                    <Grid item xs={12}>
                        <CircularProgress />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body2' gutterBottom>
                            Checking you out ...
                        </Typography>
                    </Grid>
                </Grid>
            ) : ( !isEmpty(checkoutResult) ? 
                    <Card sx={{ marginTop:  3 }}>
                        <CardContent>
                            <Grid container spacing={0} alignItems='center' justify='center'>
                                <Grid item xs={12}>
                                    { displayResult() }
                                </Grid>
                            </Grid>   
                        </CardContent>
                        <CardContent>
                            <Grid container spacing={0} alignItems='center' justify='center'>
                                <Grid item xs={12}>
                                    {checkoutResult?.result ? (
                                        <Alert severity='info' icon={false}>
                                            Thank you {checkout.guest_first_name}! We hope you had a memorable stay at {checkout.hotel_name}. 
                                            We look forward to welcoming you again soon.
                                        </Alert>
                                    ) : (
                                        <Alert severity='info' sx={{ display: 'flex', alignItems: 'center' }}>
                                            Please visit our reception to finalise your check-out.
                                        </Alert>
                                    )}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    : <span />
                 )}

                 <Dialog open={openDialog}>
                    <DialogContent>
                        <DialogContentText>
                            The payment status could not be confirmed.
                            If your payment was successful, refresh the pre-authorization status to update the folio and complete the check-out.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color='secondary'>Cancel</Button>
                        <Button onClick={handleRefresh} endIcon={<UpdateIcon />}>Re-fresh</Button>
                    </DialogActions>
                 </Dialog>
        </Container>
    )
}

export default CheckoutComplete;