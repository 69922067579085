import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { toastOnError } from '../../utils/Utils';



const initialState = {
    checkinKey: '',
    isLoading: false,
    isUpdating: false,
    isCheckingIn: false,
    checkin: {},
    reservation: {},
    linkedReservations: [],
    roomsRequired: null,
    availableRooms: [],
    roomsPreAssigned: false,
    profile: {},
    profileId: '',
    address: {},
    communication: {},
    depositFolio: {},
    ccAuthUrl: '',
    profileData: {},
    checkinResult: {},
}


export const verifyCheckIn = createAsyncThunk(
    'checkInVerify',
    async (signature, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get(`/checkin/verify/?signature=${signature}`);
            dispatch(setKey(response.data));
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const getCheckInDetails = createAsyncThunk(
    'checkInDetails',
    async (key, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get(`/checkin/start?key=${key}`)
            dispatch(setCheckInDetails(response.data))
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const postCheckInProfile = createAsyncThunk(
    'checkInProfile',
    async (profileData, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.post('/checkin/profile/', profileData);
            dispatch(setProfileData(profileData));
            toast.success(response.data);
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const getCheckInStatus = createAsyncThunk(
    'checkInStatus',
    async (key, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get(`/checkin/status?key=${key}`);
            dispatch(setStatus(response.data));
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const performCheckIn = createAsyncThunk(
    'performCheckIn',
    async (keyData, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.post('/checkin/complete/', keyData);
            dispatch(setResult(response.data));
            return response.data;
        } catch (error) {
            // toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const checkinSlice = createSlice({
    name: 'checkin',
    initialState,
    reducers: {
        setKey (state, action) {
            state.checkinKey = action.payload.response.key;
        },
        setCheckInDetails (state, action) {
            state.checkin = action.payload.checkin;
            state.reservation = action.payload.reservation_details;
            state.linkedReservations = action.payload.linked_reservations;
            state.roomsRequired = action.payload.rooms_required;
            state.availableRooms = action.payload.available_rooms;
            state.roomsPreAssigned = action.payload.rooms_pre_assigned;
            state.profile = action.payload.profile;
            state.profileId = action.payload.profile_id;
            state.address = action.payload.address;
            state.communication = action.payload.communication;
            state.depositFolio = action.payload.deposit_folio;
            state.ccAuthUrl = action.payload.cc_auth_url;
        },
        setStatus (state, action) {
            state.checkin = action.payload;
        },
        setResult (state, action) {
            state.checkinResult = action.payload;
        },
        setProfileData (state, action) {
            state.profileData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(verifyCheckIn.pending, (state) => {
            state.isLoading = true;
        }).addCase(verifyCheckIn.fulfilled, (state) => {
            state.isLoading = true;
        }).addCase(verifyCheckIn.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getCheckInDetails.pending, (state) => {
            state.isLoading = true;
        }).addCase(getCheckInDetails.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getCheckInDetails.rejected, (state) => {
            state.isLoading = false;
        }).addCase(postCheckInProfile.pending, (state) => {
            state.isUpdating = true;
        }).addCase(postCheckInProfile.fulfilled, (state) => {
            state.isUpdating = false;
        }).addCase(postCheckInProfile.rejected, (state) => {
            state.isUpdating = false;
        }).addCase(getCheckInStatus.pending, (state) => {
            state.isLoading = true;
        }).addCase(getCheckInStatus.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getCheckInStatus.rejected, (state) => {
            state.isLoading = false;
        }).addCase(performCheckIn.pending, (state) => {
            state.isCheckingIn = true;
        }).addCase(performCheckIn.fulfilled, (state) => {
            state.isCheckingIn = false;
        }).addCase(performCheckIn.rejected, (state) => {
            state.isCheckingIn = false;
        })
    }
})

export const { setCheckInDetails, setKey, setStatus, setResult, setProfileData } = checkinSlice.actions;
export default checkinSlice.reducer;
